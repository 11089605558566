/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http'

import { Observable, Observer } from 'rxjs'

import { AuthConfig } from '@libs/ng-shared/services/config'

import { ALLOW_ANONYMOUS } from '../../constants'
import { ToLogin } from './helper'

export function isAnonymous(req: HttpRequest<unknown>, options: AuthConfig): boolean {
  if (req.context.get(ALLOW_ANONYMOUS)) return true
  if (Array.isArray(options.ignores)) {
    for (const item of options.ignores) {
      if (item.test(req.url)) return true
    }
  }
  return false
}

export function throwErr(req: HttpRequest<unknown>, options: AuthConfig): Observable<HttpEvent<unknown>> {
  ToLogin(options)

  // Interrupt Http request, so need to generate a new Observable
  return new Observable((observer: Observer<HttpEvent<any>>) => {
    let statusText = ''
    statusText = `the requested URL is unauthorized`
    const res = new HttpErrorResponse({
      url: req.url,
      headers: req.headers,
      status: 401,
      statusText
    })
    observer.error(res)
  })
}
