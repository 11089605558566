import { inject, Inject, Injectable, OnDestroy } from '@angular/core'

import { BehaviorSubject, filter, interval, map, Observable, share, Subject, Subscription } from 'rxjs'

import { AuthConfig, type AppConfig } from '@libs/ng-shared/services/config'
import { APP_CONFIG } from '@libs/ng-shared/services/config/config.constants'

import { AUTH_STORE_TOKEN } from '../store'
import { AuthReferrer, ITokenModel, ITokenService } from './interface'

export function AUTH_SERVICE_TOKEN_FACTORY(): ITokenService {
  return new TokenService(inject(APP_CONFIG))
}

@Injectable()
export class TokenService implements ITokenService, OnDestroy {
  private readonly store = inject(AUTH_STORE_TOKEN)

  private refresh$ = new Subject<ITokenModel>()
  private change$ = new BehaviorSubject<ITokenModel | null>(null)
  private interval$?: Subscription
  private _referrer: AuthReferrer = {}
  private readonly _options!: AuthConfig

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {
    this._options = this.appConfig.auth
  }

  get refresh(): Observable<ITokenModel> {
    this.builderRefresh()
    return this.refresh$.pipe(share())
  }

  get loginUrl(): string | undefined {
    return this._options.loginUrl
  }

  get referrer(): AuthReferrer {
    return this._referrer
  }

  get options(): AuthConfig {
    return this._options
  }

  set(data: ITokenModel): boolean {
    const res = this.store.set(this._options.storeKey, data)
    this.change$.next(data)
    return res
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(type?: any): any
  get<T extends ITokenModel>(type?: new () => T): T
  get<T extends ITokenModel>(type?: new () => T): T {
    const data = this.store.get(this._options.storeKey)
    return type ? (Object.assign(new type(), data) as T) : (data as T)
  }

  ngOnDestroy(): void {
    this.cleanRefresh()
  }

  clear(options: { onlyToken: boolean } = { onlyToken: false }): void {
    let data: ITokenModel | null = null
    if (options.onlyToken) {
      data = this.get() as ITokenModel
      data.token = ``
      this.set(data)
    } else {
      this.store.remove(this._options.storeKey)
    }
    this.change$.next(data)
  }

  change(): Observable<ITokenModel | null> {
    return this.change$.pipe(share())
  }

  private builderRefresh(): void {
    const { refreshTime, refreshOffset } = this._options
    this.cleanRefresh()
    this.interval$ = interval(refreshTime)
      .pipe(
        map(() => {
          const item = this.get() as ITokenModel
          const expired = item.expired || item['exp'] || 0
          if (expired <= 0) {
            return null
          }

          const curTime = new Date().valueOf() + refreshOffset
          return expired <= curTime ? item : null
        }),
        filter(v => v != null)
      )
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .subscribe(res => this.refresh$.next(res!))
  }

  private cleanRefresh(): void {
    if (this.interval$ && !this.interval$.closed) {
      this.interval$.unsubscribe()
    }
  }
}
