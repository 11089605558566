import { CookieService } from '@libs/ng-shared/services/storage'

import { ITokenModel } from '../token'
import { IStore } from './interface'

export class CookieStore implements IStore {
  constructor(private cookieSrv: CookieService) {}

  get(key: string): ITokenModel {
    try {
      return JSON.parse(this.cookieSrv.get(key) || '{}')
    } catch (ex) {
      console.error(`CookieStore: Invalid key-value format ${key}`, ex)
      return {} as ITokenModel
    }
  }

  set(key: string, value: ITokenModel | null | undefined): boolean {
    const expires = (value?.expired ?? 0) / 1e3
    this.cookieSrv.put(key, JSON.stringify(value ?? {}), { expires })
    return true
  }

  remove(key: string): void {
    this.cookieSrv.remove(key)
  }
}
