/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'

import { AuthConfig } from '@libs/ng-shared/services/config'
import { APP_CONFIG } from '@libs/ng-shared/services/config/config.constants'

import { isAnonymous, throwErr } from '../base.interceptor'
import { CheckSimple } from '../helper'
import { AUTH_SERVICE_TOKEN } from '../interface'
import { SimpleTokenModel } from './simple.model'

function newReq(req: HttpRequest<unknown>, model: SimpleTokenModel, options: AuthConfig): HttpRequest<unknown> {
  const { tokenSendTemplate, tokenSendKey } = options
  const token = tokenSendTemplate.replace(/\$\{([\w]+)\}/g, (_: string, g) => model[g])
  switch (options.tokenSendPlace) {
    case 'header':
      req = req.clone({
        setHeaders: { [tokenSendKey]: token }
      })
      break
    case 'body':
      // eslint-disable-next-line no-case-declarations
      const body: any = req.body || {}
      body[tokenSendKey] = token
      req = req.clone({})
      break
    case 'url':
      req = req.clone({
        params: req.params.append(tokenSendKey, token)
      })
      break
  }
  return req
}

export const authSimpleInterceptor: HttpInterceptorFn = (req, next) => {
  const appConfig = inject(APP_CONFIG)
  const authConf = appConfig.auth

  if (isAnonymous(req, authConf)) return next(req)

  const model = inject(AUTH_SERVICE_TOKEN).get() as SimpleTokenModel
  if (CheckSimple(model)) return next(newReq(req, model, authConf))

  return throwErr(req, authConf)
}
