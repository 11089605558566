import { ITokenModel } from '../token'
import { IStore } from './interface'

// -----------------------------------------------------------------------------------------------------
// @ lost after close tab
// -----------------------------------------------------------------------------------------------------
export class MemoryStore implements IStore {
  private cache: { [key: string]: ITokenModel | null } = {}

  get(key: string): ITokenModel {
    return this.cache[key] || ({} as ITokenModel)
  }

  set(key: string, value: ITokenModel): boolean {
    this.cache[key] = value
    return true
  }

  remove(key: string): void {
    this.cache[key] = null
  }
}
