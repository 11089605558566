import { ITokenModel } from '../token'
import { IStore } from './interface'

// -----------------------------------------------------------------------------------------------------
// @ lost after closing the browser
// -----------------------------------------------------------------------------------------------------

export class SessionStore implements IStore {
  get(key: string): ITokenModel {
    return JSON.parse(sessionStorage.getItem(key) || '{}') || {}
  }

  set(key: string, value: ITokenModel | null): boolean {
    sessionStorage.setItem(key, JSON.stringify(value))
    return true
  }

  remove(key: string): void {
    sessionStorage.removeItem(key)
  }
}
