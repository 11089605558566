/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITokenModel } from '../interface'
import { urlBase64Decode } from './jwt.helper'

export interface JWT {
  [key: string]: any
  [key: number]: any

  /**
   * Issuerd
   */
  iss: string
  /**
   * Issued At
   */
  iat: string
  /**
   * Subject
   */
  sub: string
  /**
   * Expiration Time
   */
  exp: number
  /**
   * Audience
   */
  aud: string
  /**
   * Not Before
   */
  nbf: string
  /**
   * JWT ID
   */
  jti: string
}

export class JWTTokenModel implements ITokenModel {
  [key: string]: any

  token: string | null | undefined

  expired?: number

  get payload(): JWT {
    const parts = (this.token || '').split('.')
    if (parts.length !== 3) throw new Error('JWT must have 3 parts')

    const decoded = urlBase64Decode(parts[1])
    return JSON.parse(decoded)
  }

  // Get the expiration timestamp (unit: ms)
  get exp(): number | null {
    const decoded = this.payload
    // eslint-disable-next-line no-prototype-builtins
    if (!decoded.hasOwnProperty('exp')) return null
    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date.valueOf()
  }

  // Check if the token has expired.
  // It is valid when the payload contains the exp field. If there is no exp field, return null directly.
  isExpired(offsetSeconds: number = 0): boolean | null {
    const exp = this.exp
    if (exp == null) return null

    return !(exp > new Date().valueOf() + offsetSeconds * 1000)
  }
}
