import { DOCUMENT } from '@angular/common'
import { inject } from '@angular/core'
import { Router } from '@angular/router'

import { AuthConfig } from '@libs/ng-shared/services/config'

import { JWTTokenModel, SimpleTokenModel } from './index'
import { AUTH_SERVICE_TOKEN, ITokenService } from './interface'

export function CheckSimple(model: SimpleTokenModel | null): boolean {
  return model != null && typeof model.token === 'string' && model.token.length > 0
}

export function CheckJwt(model: JWTTokenModel, offset: number): boolean {
  try {
    return model != null && !!model.token && !model.isExpired(offset)
  } catch (err: unknown) {
    console.warn(`${(err as { message: string }).message}, jump to loginUrl`)
    return false
  }
}

export function ToLogin(options: AuthConfig, url?: string): void {
  const router = inject(Router)
  const token = inject(AUTH_SERVICE_TOKEN) as ITokenService
  const doc = inject(DOCUMENT)

  if (token.referrer) {
    token.referrer.url = url || router.url
  }

  if (options.tokenInvalidRedirect) {
    setTimeout(() => {
      if (/^https?:\/\//g.test(options.loginUrl)) {
        doc.location.href = options.loginUrl as string
      } else {
        router.navigate([options.loginUrl]).then(() => console.log('redirect to login page'))
      }
    })
  }
}
