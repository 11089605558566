import { ITokenModel } from '../token'
import { IStore } from './interface'

export function STORE_TOKEN_LOCAL_FACTORY(): IStore {
  return new LocalStore()
}

export class LocalStore implements IStore {
  get(key: string): ITokenModel {
    return JSON.parse(localStorage.getItem(key) || '{}') || {}
  }

  set(key: string, value: ITokenModel | null): boolean {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  }

  remove(key: string): void {
    localStorage.removeItem(key)
  }
}
