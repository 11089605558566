/*
  app store include a number of states which should shared cross whole application
 */
import { computed, inject } from '@angular/core'

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals'

import { withStorage } from '@libs/ng-core/store'
import { IChart } from '@libs/payload'

import { ApiService } from '#core/services/api.service'

interface AppState {
  authenticated: boolean
  language: 'en' | 'zh'
  styles: {
    mode: 'dark' | 'light'
    theme: 'default'
  }
  // user对象为User类型或者不存在
  user: {
    name?: string
    avatar?: string
    tier?: string // standard | vip
    id?: string
  } | null
  metaCharts: IChart[]
}

const initialState: AppState = {
  authenticated: false,
  language: 'zh',
  styles: {
    mode: 'light',
    theme: 'default'
  },
  user: null,
  metaCharts: []
}

export const AppStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withStorage('app', localStorage),
  withComputed(({ user }) => ({
    isVip: computed(() => user()?.tier === 'vip')
  })),
  withMethods((store, apiService = inject(ApiService)) => ({
    login: (): void => patchState(store, () => ({ authenticated: true })),
    logout: (): void => patchState(store, () => ({ authenticated: false, user: null })),
    switchLang: (lang: 'en' | 'zh'): void => patchState(store, () => ({ language: lang })),
    switchMode: (mode: 'dark' | 'light'): void => patchState(store, state => ({ styles: { ...state.styles, mode } })),
    updateUserName: (name: string): void => patchState(store, state => ({ user: { ...state.user, name } })),
    updateUserAvatar: (avatar: string): void => patchState(store, state => ({ user: { ...state.user, avatar } })),
    updateUserTier: (tier: string): void => patchState(store, state => ({ user: { ...state.user, tier } })),
    updateUserId: (id: string): void => patchState(store, state => ({ user: { ...state.user, id } })),
    cleanUser: (): void => patchState(store, () => ({ user: null })),
    async getMetaCharts(): Promise<void> {
      apiService.getMetaCharts().subscribe(res => {
        patchState(store, () => ({ metaCharts: res.list }))
      })
    }
  }))
)
